import React from 'react';
import { store } from "../redux/storeConfig/store";
import {Navigate, Outlet} from 'react-router-dom'

const useAuth=()=>{

  const { user } = store.getState().auth.login;
  if(user){
    return true
  } else {
    return false
  }

}

const  PublicRoutes=(props) =>{

  const auth = useAuth()

  return auth?<Navigate to="/dashboard/xdata/buckets"/>: <Outlet/>
}

export default PublicRoutes;