import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'


export const getAllWorkspaceUsers = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_USER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.workspaceUsers +  '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_USER_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        else{
          dispatch({
            type: "REQUEST_ALL_USER_FAILED",
            payload: err.response.status
          })
        }
        
      })
  }
}

export const getAlluserWorkspaceUsers = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_USER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.allworkspaceUsers +  "?search=" + payload.search +  '&offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_USER_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        else{
          dispatch({
            type: "REQUEST_ALL_USER_FAILED",
            payload: err.response.status
          })
        }
        
      })
  }
}

export const createWorkspaceUser = (payload, companyid) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "CREATE_REQUEST_WORKSPACEUSER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.workspaceUsers, {
        "email": payload.email,
        "password": payload.password,
        "password2": payload.confirm,
        "company_id": companyid,
        "Databases": payload.database,
        "Analytics": payload.analytics,
        "Themes": payload.themes,
        "Graphs": payload.graphs,
      }, config)
      .then(response => {
        dispatch({
          type: "CREATE_REQUEST_WORKSPACEUSER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {    
          if(err.response.status === 500){
            dispatch({
              type: "CREATE_REQUEST_WORKSPACEUSER_FAILED_500",
              payload: true
            })
          }     
          dispatch({
            type: "CREATE_REQUEST_WORKSPACEUSER_FAILED",
            payload: err.response.data
          })
      })
  }
}

export const editWorkspaceUser = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "UPDATE_REQUEST_WORKSPACEUSER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.workspaceUsers + payload.id + "/update/", {       
        "databases": payload.editdatabase,
        "analytics": payload.editanalytics,
        "themes": payload.editthemes,
        "graphs": payload.editgraphs,
      }, config)
      .then(response => {
        dispatch({
          type: "UPDATE_REQUEST_WORKSPACEUSER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 500){
          dispatch({
            type: "UPDATE_REQUEST_WORKSPACEUSER_FAILED_500",
            payload: true
          })
        }
          dispatch({
            type: "UPDATE_REQUEST_WORKSPACEUSER_FAILED",
            payload: err.response.data.error
          })
        
      })
  }
}

export const editAllWorkspaceUser = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "UPDATE_REQUEST_WORKSPACEUSER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.allworkspaceUsers + payload.id + "/newupdate/", {       
        "databases": payload.editdatabase,
        "analytics": payload.editanalytics,
        "themes": payload.editthemes,
        "graphs": payload.editgraphs,
      }, config)
      .then(response => {
        dispatch({
          type: "UPDATE_REQUEST_WORKSPACEUSER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 500){
          dispatch({
            type: "UPDATE_REQUEST_WORKSPACEUSER_FAILED_500",
            payload: true
          })
        }
          dispatch({
            type: "UPDATE_REQUEST_WORKSPACEUSER_FAILED",
            payload: err.response.data.error
          })
        
      })
  }
}

export const deleteWorkspaceUser = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "DELETE_REQUEST_WORKSPACEUSER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.workspaceUsers + payload.id + "/delete/", config)
      .then(response => {
        dispatch({
          type: "DELETE_REQUEST_WORKSPACEUSER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 403){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "DELETE_REQUEST_WORKSPACEUSER_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "DELETE_REQUEST_WORKSPACEUSER_FAILED",
          payload: err.response.data.error
        })
      })
  }
}



