const initalState = {
    userRole: "admin",
    loading: false,
    user: null,
    error:null,
    errorStatus: null
  }
  
  export const login = (state = initalState, action) => {  
    switch (action.type) {
      case "LOGIN_WITH_EMAIL_REQUEST": {
        return { ...state, loading: true, error: null }
      }
      case "LOGIN_WITH_EMAIL_SUCCESS": {
        return { ...state, user: action.payload, error: null, loading: false, }
      }
      case "LOGIN_WITH_EMAIL_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }
      case "LOGIN_WITH_EMAIL_FAILED_STATUS": {
        return { ...state, loading: false, error: null, errorStatus: action.payload }
      }
      case "INITIAL_STATE": {
        return { ...state, loading: false, error: null }
      }
      case "LOGOUT": {
        return { ...state, user: null, error: null, loading: false, }
      }
      default: {
        return state
      }
    }
  }
  