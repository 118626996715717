import React, {useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import styles from "./styles";
import { WhiteLayout } from 'layouts';
import Button from '@mui/material/Button';
import ResetLogo from "assets/images/reset.svg";
import { useDispatch, useSelector } from "react-redux";
import {companyActivationAction} from "../../redux/actions/companyActivation/companyActivationActions"
import queryString from 'query-string';


import { ReactComponent as Logo } from "assets/images/corob-logo-red.svg";

const useStyles = makeStyles(styles)

export default function CompanyActivation(props) {
    const classes = useStyles()
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { activationError } = useSelector((state) => state.userActivation.activation);

    useEffect(() => { 
        const parsed = queryString.parse(window.location.search);
        dispatch(companyActivationAction(parsed.token))
    },[]);

    const parsed = queryString.parse(window.location.search);

    const handleLoginClick = () => {
        navigate("/sign-in")
    }
    
  return (
    <WhiteLayout>
        <div className={classes.container}>
            <Logo className={classes.logo} />
            <div className={classes.contentdiv}>
                
                {
                    activationError ?
                    <>
                    <div className={classes.errorText}>{activationError.message}</div>
                    </>
                    :
                    <>
                    <img src={ResetLogo} alt='success' className={classes.icon} /> 
                    <div className={classes.logoutText}>Company Registration Completed Successfully!</div>
                    
                    </>
                }
                
            </div>
            {/* <Button onClick={() =>   dispatch(companyActivationAction(parsed.token))} style={{marginTop: 30}}>register  </Button> */}
            <Button onClick={handleLoginClick} style={{marginTop: 30}}>Login </Button>
        </div>
    </WhiteLayout>
  )
}