const initalState = {
    loading: false,
    workspaceusers: null,
    error:null,
    createLoading: false, 
    createError: null, 
    createError500: null, 
    createSuccess: null,
    updateLoading: false, 
    updateError: null,
    updateError500: null,  
    updateSuccess: null,
    deleteLoading: false,
    deleteError: null,
    deleteError500: null,
    deleteSuccess: false
  }
  
  export const user = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_ALL_USER": {
        return { ...state, loading: true, error: null, createLoading: false, createError: null, createSuccess: null, updateSuccess: null, updateError: null, deleteSuccess: false }
      }
      case "REQUEST_ALL_USER_SUCCESS": {
        return { ...state, workspaceusers: action.payload, error: null, loading: false, }
      }
      case "REQUEST_ALL_USER_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }
      case "CREATE_REQUEST_WORKSPACEUSER": {
        return { ...state, createLoading: true, createError: null, createSuccess: null }
      }
      case "CREATE_REQUEST_WORKSPACEUSER_SUCCESS": {
        return { ...state, createSuccess: action.payload, createError: null, createLoading: false, }
      }
      case "CREATE_REQUEST_WORKSPACEUSER_FAILED": {
        return { ...state, createLoading: false, createError: action.payload }
      }
      case "CREATE_REQUEST_WORKSPACEUSER_FAILED_500": {
        return { ...state, createLoading: false, createError500: action.payload }
      }
      case "UPDATE_REQUEST_WORKSPACEUSER": {
        return { ...state, updateLoading: true, updateError: null, updateSuccess: null }
      }
      case "UPDATE_REQUEST_WORKSPACEUSER_SUCCESS": {
        return { ...state, updateSuccess: action.payload, updateError: null, updateLoading: false, }
      }
      case "UPDATE_REQUEST_WORKSPACEUSER_FAILED": {
        return { ...state, updateLoading: false, updateError: action.payload }
      }
      case "UPDATE_REQUEST_WORKSPACEUSER_FAILED_500": {
        return { ...state, updateLoading: false, updateError500: action.payload }
      }
      case "DELETE_REQUEST_WORKSPACEUSER": {
        return { ...state, deleteLoading: true, deleteError: null, deleteSuccess: false }
      }
      case "DELETE_REQUEST_WORKSPACEUSER_SUCCESS": {
        return { ...state, deleteSuccess: true, deleteError: null, deleteLoading: false, }
      }
      case "DELETE_REQUEST_WORKSPACEUSER_FAILED": {
        return { ...state, deleteLoading: false, deleteError: action.payload, deleteSuccess: false }
      }
      default: {
        return state
      }
    }
  }
  