import { history } from "../../../history"
import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";

 export const loginWithJWT = user => {
  return dispatch => {
    dispatch({
      type: "LOGIN_WITH_EMAIL_REQUEST"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.login, {
        email: user.email,
        password: user.password
      }, config)
      .then(response => {
        dispatch({
          type: "LOGIN_WITH_EMAIL_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        if(err.response.status === 0 || err.response.status === 500){
          dispatch({
            type: "LOGIN_WITH_EMAIL_FAILED_STATUS",
            payload: err.response.status
          })
        } 
        dispatch({
          type: "LOGIN_WITH_EMAIL_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const logoutWithJWT = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT", payload: {} });
    history.push("/logout");
    window.location.reload();
  }
}

