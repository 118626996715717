const styles = (theme) => ({
    heading:{      
        marginTop:10,
        color:"#fff",
        fontSize:30, 
        fontFamily:"Roboto",
        fontWeight:"400", 
        [theme.breakpoints.up("xl")]:{
            fontSize:"40px !important", 
        }    
    },
    subheading:{
        color: "#fff",
        marginTop:"18px !important",
        fontFamily:"Roboto",
        fontSize:15,
        marginBottom:"25px !important",
        "&:after":{
            borderTop: "thin solid #FF647D !important"
        },
        "&:before":{
            borderTop: "thin solid #FF647D !important"
        },
        [theme.breakpoints.up("xl")]:{
            fontSize:"22px !important", 
        } 
    },
    divider:{
        paddingLeft: "50px !important",
        paddingRight: "50px !important",
    },
    head:{
       color:"fff !important"
    },
    text:{
         
        color:"#fff !important"
    },
    
    check:{
        color:"#fff",
        fontSize:18, 
        fontFamily:"Roboto",
        fontWeight:"400",  
        
    },
    down:{
       
        marginTop:10,
             
    },
   
    link:{
        fontSize:15, 
        fontFamily:"Roboto !important",
        fontWeight:"400",  
        [theme.breakpoints.up("xl")]:{
            fontSize:"20px !important", 
        }
    },
    button:{
        width:"100%",
        backgroundColor:"#000 !important",
        marginTop:6,
        fontSize:'20px !important', 
         fontFamily:"Roboto",
         fontWeight:500, 
        [theme.breakpoints.down("xl")]:{
         marginTop:1,
         fontSize:'18px !important'
        },
        [theme.breakpoints.up("xl")]:{
            minHeight: "65px!important",
            minWidth:  "160px !important",
            fontSize: "22px!important",
        }
    },
    bottom:{
        fontSize:20, 
        fontFamily:"Roboto",
        fontWeight:"400",  
        textAlign:"center",
        marginTop:"20px !important"
    },
    ss:{
        color: '#fff'
    },
//     placeholder:{
//        color:"#C8102E !important"
//    },
   

    
textField: {
    "& input":{
        backgroundColor:"red"
    },
    "& input::placeholder": {
      fontSize: "20px"
    }
  },
first:{
    color:"#fff",
    opacity:1,
    fontSize: 15,
    [theme.breakpoints.up("xl")]:{
        fontSize:"20px !important", 
    }
},
tag:{
    fontSize: 16,
    fontWeight:500
},

    


})
export default styles