import axios from "axios"
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'


 export const getImpersonateUserData = (payload) => { 
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_IMPERSONATE_USER_DATA"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.impersonate + payload.id + '/' + payload.otp + '/', config)
      .then(response => {
        dispatch({
          type: "REQUEST_IMPERSONATE_USER_DATA_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_IMPERSONATE_USER_DATA_FAILED",
          payload: err.response.data.error
        })
      })
  }
}
