import React,{useEffect} from 'react'
import { CustomTextField } from 'elements';
import { Button,Link,Grid,Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from "./style";
import { useFormik } from "formik";
import {  string } from "yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux"
import { loginWithJWT } from "../../redux/actions/auth/loginActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(styles)

export default function SignInForm() {
  const { loading,error,user, errorStatus } = useSelector((state) => state.auth.login);
  const classes = useStyles()
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {t} = useTranslation('common');

  useEffect(() => {  
    if(error){
      Swal.fire({
        title: t('signIn.failed'),
        text: error === "Entered Email/Password is incorrect" ? t('signIn.inCorrectError'): t('signIn.noActiveAccount'),
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',
        confirmButtonText: t('companyAdd.close')
      })
      dispatch({
        type: "LOGIN_WITH_EMAIL_FAILED",
        payload: null
      })
    }    
  }, [error]);

  const formik = useFormik({
    initialValues: {    
      email: "",    
      password: "",    
    },
    validationSchema: Yup.object({    
      email: string().required(t('signIn.emailValidation')).email(t('signIn.Invalidemail')),    
      password: string().required(t('signIn.passwordValidation')),
    }),
    onSubmit : (values, formikHelpers)=> {
      dispatch(loginWithJWT(values))      
    }
  });

  useEffect(() => {  
    if(user?.is_superuser){
      navigate("/dashboard/xdata/buckets")
    }else if(user?.is_workspace_admin){
      navigate("/dashboard/workspace-users")
    }else if(user?.is_colordb_editor){
      navigate("/dashboard/xdata/buckets")
    }else if(user?.is_historians){
      navigate("/dashboard/xdata/analytics")
    }else if(user?.is_theme_editor){
      navigate("/dashboard/themes")
    }
  }, [user]);

  useEffect(() => {  
    dispatch({
      type: "INITIAL_STATE",
      payload: null
    })
  }, []);

  useEffect(() => {  
    if(errorStatus === 0|| errorStatus === 500){
      Swal.fire({
        title: 'Failed',
        text: 'Login Failed',
        icon: 'error',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "LOGIN_WITH_EMAIL_FAILED_STATUS",
        payload: null
      })
    }
    
  }, [errorStatus]);


  return (
   <React.Fragment>
      <div className={classes.heading}>{t('signIn.title')}</div>
      <Divider classes={{root:classes.subheading,wrapper:classes.divider}} >{t('signIn.subTitle')}</Divider>
      <form onSubmit={formik.handleSubmit}>
        <div  className="public-textbox">
       <CustomTextField 
          // required="false"
          label={t('signIn.email')}
          name="email"
          type="email" 
          placeholder={t('signIn.email')}
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.email} 
          error = {formik.touched.email && Boolean(formik.errors.email)} 
          helperText = {formik.touched.email && formik.errors.email}
          login={true}
        />
        <CustomTextField 
          label={t('signIn.password')}
          name="password"
          type="password" 
          placeholder={t('signIn.password')}
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.password} 
          error = {formik.touched.password && Boolean(formik.errors.password)} 
          helperText = {formik.touched.password && formik.errors.password}
          login={true}
        />
        </div>
        <Grid  className={classes.down} container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={6}>                    
          </Grid>
          <Grid item xs={6} textAlign="right">
            <Link className={classes.link} color="#FFFFFF" href="/forgot-password" >{t('signIn.forgotYourPassword')}</Link>
          </Grid>          
        </Grid>       
        <Button type="submit" className={classes.button}>
          {loading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('signIn.login')}
        </Button>
      </form>
      <div className={classes.bottom}>
        <p className={classes.first}>{t('signIn.notRegisteredYet')} <Link  href="/sign-up" sx={{ "&:hover": { color: "#FFFFFF" },opacity:1}} color="#FFFFFF" >{t('signIn.createAnAccount')}</Link></p> 
      </div>    
   </React.Fragment>                  
  )
}
